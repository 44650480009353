/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #4a90e2, #9013fe);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.App {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  width: 400px;
}

/* Coin Styling */
.coin {
  width: 150px;
  height: 150px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  background: radial-gradient(circle, #ffd700, #ff8c00);
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease;
}

.coin.flipping {
  animation: flip 1s infinite linear;
}

@keyframes flip {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* Button Styling */
button {
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  background: #ffc107;
  color: #333;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

button:disabled {
  background: #999;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background: #ff5722;
}

/* History Section */
.history {
  margin-top: 30px;
}

.history h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.history ul {
  list-style-type: none;
  padding: 0;
}

.history li {
  padding: 8px 0;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin: 5px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.percentages {
  margin-top: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.percentages h2 {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 22px;
}

.percentages p {
  font-size: 18px;
  margin: 5px 0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}
